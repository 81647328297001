<template>
    <div class="animation-container">
        <b-row>
            <b-col cols="12" class="d-flex justify-content-center align-items-center mb-4">
                <div class="logo-wrapper">
                    <img src="../../../assets/img/island-candos-logo.png" class="logo-image" alt="Logo" height="85" />
                </div>
            </b-col>

            <b-col cols="12" class="d-flex justify-content-center align-items-center mb-4">
                <h2 class="text-title">{{ $t('room_reservation_system') }}</h2>
            </b-col>

            <b-col cols="12" class="d-flex justify-content-center align-items-center mb-4">
                <div class="circle">
                    <svg
                        class="checkmark"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 52 52"
                    >
                        <circle
                            class="checkmark__circle"
                            cx="26"
                            cy="26"
                            r="25"
                            fill="none"
                        />
                        <path
                            class="checkmark__check"
                            fill="none"
                            d="M14 27l10 10 16-16"
                        />
                    </svg>
                    <h6 class="success-message">Your reservation request has been submitted.</h6>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    metaInfo() {
        return {
            title: this.$t('housing_reservation_form_success'),
        };
    },
};
</script>

<style scoped>
/* Genel Stiller */
.animation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f8f5;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}

.logo-wrapper {
    max-width: 100%;
    display: flex;
    justify-content: center;
}

.logo-image {
    max-width: 100%;
    height: auto;
}

.circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.checkmark {
    width: 80px;
    height: 80px;
    stroke-width: 2;
    stroke: #4caf50;
    stroke-miterlimit: 10;
    stroke-linecap: round;
    stroke-linejoin: round;
    animation: scaleCircle 0.6s ease-in-out;
    margin-bottom: 15px;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke: #4caf50;
    fill: none;
    animation: drawCircle 0.6s linear forwards;
}

.checkmark__check {
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: drawCheck 0.4s 0.6s ease-in-out forwards;
}

.text-title {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
}

.success-message {
    font-size: 1.2rem;
    color: #333;
    font-weight: 500;
    margin-top: 10px;
}

@keyframes drawCircle {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes drawCheck {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scaleCircle {
    0% {
        transform: scale(0);
    }
    80% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

/* Mobil Uyumlu Stiller */
@media (max-width: 768px) {
    .circle {
        width: 70%;
    }

    .checkmark {
        width: 60px;
        height: 60px;
    }

    .text-title {
        font-size: 1.5rem;
    }

    .success-message {
        font-size: 1rem;
    }
}

@media (max-width: 576px) {
    .circle {
        width: 90%;
    }

    .checkmark {
        width: 50px;
        height: 50px;
    }

    .text-title {
        font-size: 1.2rem;
    }

    .success-message {
        font-size: 0.9rem;
    }
}
</style>
